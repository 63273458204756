<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">学员统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ pageTitle }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div style="margin: 0 14px 20px">
          <span>姓名：{{ userName }}</span>
          <span style="margin: 0 40px">身份证号：{{ idcard }}</span>
          <span style="margin: 0 20px">电话：{{ mobile }}</span>
          <span style="margin: 0 20px">课程名称：{{ courseName }}</span>
        </div>
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="课件名称" class="searchboxItem ci-full">
              <span class="itemLabel">课件名称:</span>
              <el-input
                v-model="kpointName"
                type="text"
                size="small"
                placeholder="请输入课件名称"
                clearable
              />
            </div>
            <div class="df" style="padding-right: 45px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="课件名称"
                align="left"
                show-overflow-tooltip
                prop="kpointName"
              />
              <el-table-column
                label="原因"
                align="left"
                show-overflow-tooltip
                prop="stopReason"
              />
              <el-table-column
                label="当前学习位置"
                align="left"
                show-overflow-tooltip
                prop="oldTime"
                width="180"
              >
                <template slot-scope="scope">
                  {{ getTime(scope.row.oldTime) }}
                </template>
              </el-table-column>
              <el-table-column
                label="归零时间"
                align="left"
                show-overflow-tooltip
                prop="zeroTime"
                width="180"
              >
                <template slot-scope="scope">
                  {{ scope.row.zeroTime }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
export default {
  name: "set/TrajectoryList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      kpointName: "", // 课件名称
      pageTitle: this.$route.query.pageTitle,
      userName: "",
      mobile: "",
      courseName: "",
      idcard: "",
      userId: "",
      projectCourseId: "",
      Obj: {},
    };
  },
  created() {
    console.log(this.$route.query.data);
    this.Obj = JSON.parse(this.$route.query.data);
    this.userName = this.Obj.userName;
    this.mobile = this.Obj.mobile;
    this.courseName = this.Obj.courseName;
    this.idcard = this.Obj.idcard;
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    init() {
      this.getTableHeight();
      this.getData(-1);
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userId: this.$route.query.userId,
        projectCourseId: this.$route.query.projectCourseId,
      };

      if (this.kpointName) {
        params.kpointName = this.kpointName;
      }

      this.doFetch({
        url: "/biz/coursestudy/studyStopLog/pageList",
        params,
        pageNum,
      });
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    /* 获取高度 */
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 60;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
